<template>
  <v-btn
      color="primary"
      @click="$emit('click')">
    <v-icon small>far fa-plus-circle</v-icon>
    <span class="ml-2">{{text}}</span>
  </v-btn>
</template>

<script>
export default {
  name: "BtnDatatableAdd",

  props: {
    text: {type: String, default: ""}
  }
}
</script>

<style scoped>

</style>